import PasswordInput from "../inputs/PasswordValidInput.js";
import AccountSection from "./AccountSection"
import { useState, useContext } from "react";
import toast from "react-hot-toast"
import FormButton from "../auth/FormButton.js";
import { GlobalContext } from "../context/GlobalContext.jsx";

const ChangePasswordSection = () => {
  const [error, setError] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const { state } = useContext(GlobalContext);

  const onChangePasswordClick = async () => {
    if (!state || !state.user) {
      // do something
      console.error("state error")
      return;
    }
    const response = await toast.promise(state.user.updatePassword(newPassword, oldPassword), {
      loading: "Changing password...",
      success: "Password successfully changed!",
      error: "Error changing password"
    }).catch((err) => {
      return "error";
    });

    if (response === "error") {
      return;
    }
  }

  return (
    <AccountSection title="Change Password">
      <div className="w-full flex flex-col items-center">
        <div className="w-full space-x-4 flex my-2 text-left">
          <PasswordInput
            label={"Current Password"}
            placeholder={"Enter current password..."}
            value={oldPassword}
            setGlobalError={setError}
            setValue={setOldPassword}
          />
          <PasswordInput
            label={"New Password"}
            placeholder={"Enter new password..."}
            value={newPassword}
            setGlobalError={setError}
            setValue={setNewPassword}
          />
        </div>
        <FormButton
          onClick={onChangePasswordClick}
          width="w-1/2"
          disabled={error}
          isSecondary
        >
          Change Password
        </FormButton>
      </div>
    </AccountSection>
  )
}
export default ChangePasswordSection
