import { memo } from "react"
import { dateFormatOptions } from "../lib/Constants"

const HistoryCard = ({ title, desc, image, date, link }) => {
  const onClickCard = () => {
    window.location.href = link
  }
  return (
    <div onClick={onClickCard} className="flex flex-col items-center w-screen h-[412px] phablet:w-[326px] cursor-pointer m-1">
      <img alt="favicon-logo" className="flex justify-center items-center bg-dark text-light dark:bg-light dark:text-dark rounded-t-xl w-full h-2/5" src={image} />
      <div className="h-3/5 w-full p-5 my-2">
        <h6 className="title text-[20px]">{title}</h6>
        <p className="text-[18px] text-dark-gray">{desc.length > 100 ? desc.substring(0, 100) + "..." : desc}</p>
      </div>
      <p className="text-left w-full px-5 text-[18px] text-dark-gray">{new Date(date).toLocaleDateString("en-us", dateFormatOptions)}</p>
    </div>
  )
}
export default memo(HistoryCard)