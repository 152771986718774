import toast from "react-hot-toast"
import FormButton from "../auth/FormButton"
import AccountSection from "./AccountSection"
import EmailInput from "../inputs/EmailInput"
import { useState, useContext } from "react"
import { GlobalContext } from "../context/GlobalContext"

const ChangeEmailSection = () => {
  const [email, setEmail] = useState("")
  const [error, setError] = useState(false)
  const {state} = useContext(GlobalContext)

  const onChangeEmail = async () => {
    if (!state || !state.user) {
      toast.error("Error changing email")
      return;
    }
    const user = state.user;
    const didChange = await toast.promise(user.changeEmail(email), {
      loading: "Changing email...",
      success: "Email changed!",
      error: "Error changing email"
    })
    if (didChange) {
      setEmail("")
    }

  }

  return (
    <AccountSection title="Change Email">
      <div className="w-full flex flex-col items-center text-left px-12">
        <div className="w-full flex flex-col mb-2">
          <EmailInput
            value={email}
            setGlobalError={setError}
            setValue={setEmail}
            label="New email"
            placeholder="Enter your new email..."
          />
        </div>
        <FormButton
          disabled={error}
          onClick={onChangeEmail}
          width="w-1/2"
          isSecondary
        >
          Change Email
        </FormButton>
      </div>
    </AccountSection>
  )
}
export default ChangeEmailSection