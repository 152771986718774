import { BACKEND_API_URL } from "../lib/Constants";

export default class Summarize {
    constructor(token) {
        this.token = token;
    }

    getHeaders(base_headers = {}) {
      let headers = {
        "Access-Control-Allow-Origin": "*",
      };

      if (this.token != null) {
        headers["Authorization"] = `Bearer ${this.token}`;
      }

      return Object.assign(headers, base_headers);
    }

    async summarizeArticle(url, summaryLevel) {
        const response = await fetch(`${BACKEND_API_URL}/summarize/article`, {
            method: 'POST',
            headers: this.getHeaders({'Content-Type': 'application/json'}),
            body: JSON.stringify({
                url: url
            })
        });
        return await response.json()

    }
    async summarizeVideo(link, summaryLevel) {
        const response = await fetch(`${BACKEND_API_URL}/summarize/video`, {
            method: 'POST',
            headers: this.getHeaders({'Content-Type': 'application/json'}),
            body: JSON.stringify({
                url: link
            })
        }).then(response => response.json());
        return response;
    }

}
