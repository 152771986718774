import User from "../api/User";

export const POSTGREST_API_URL = "https://db.summarily.net:31443";
export const BACKEND_API_URL = "https://api.summarily.net:31443";
export const FRONTEND_API_URL = "https://summarily.net";


export const PAGE_SIZE = 10;

/*
Check for valid token or state
*/
export const CheckTokenAndState = (token, state, dispatch) => {
    // console.debug("CheckTokenAndState", token, state);
    if (state && state.user) {
        return true;
    } else if (token) {
        const user = new User(token);
        dispatch({type: "SET_USER", payload: {
            user: user
        }});
        return true;
    }
    return false;
}

export const GET_USER_HISTORY_QUERY = `
    document_id,
    documents (created_at, source_url, title, image, summary)
`


export  const dateFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' }
