import { useState } from "react";
import { FaCog } from "react-icons/fa";
import { IoMdCloseCircle } from "react-icons/io";
import { AnimatePresence, motion } from "framer-motion";

const SummaryLevelSelector = ({ level, setLevel }) => {
  const [collapsed, setCollapsed] = useState(true);

  const toggleCollapsed = () => setCollapsed(prev => !prev);
  const collapse = () => setCollapsed(true);
  const setShort = () => setLevel(0);
  const setNormal = () => setLevel(1);
  const setLong = () => setLevel(2);

  return (
    <div className="relative">
      
      <motion.button
        className="flex justify-center items-center w-fit h-fit text-6xl phablet-max:mt-10 m-0 p-0"
        onClick={toggleCollapsed}
        whileTap={{ scale: 0.9 }}
      >
        <FaCog />
      </motion.button>
      <AnimatePresence>
        {!collapsed &&
          <motion.div
            className="z-40 top-0 -right-full phablet:right-0 mr-16 absolute flex flex-col w-96 h-fit min-h-16 bg-white border-col border-2 rounded-xl"
            initial={{ scale: 0.0 }}
            animate={{ scale: 1.0 }}
            exit={{ scale: 0.0 }}
          >
            <button onClick={collapse} className="text-red-500 text-3xl absolute top-0 right-0">
              <IoMdCloseCircle />
            </button>
            <div className="flex flex-col h-full w-full subtitle !text-dark py-3">
              <p className="w-full text-center mb-5 text-[2rem] font-[600]">Summary Level</p>
              <div className="flex phablet-max:px-10 phablet-max:flex-col phablet-max:space-y-4 w-full justify-evenly phablet:space-x-4">
                <button className={`${level === 0 ? "btn" : "btn-secondary"} px-4`} onClick={setShort}>Short</button>
                <button className={`${level === 1 ? "btn" : "btn-secondary"} px-4`} onClick={setNormal}>Normal</button>
                <button className={`${level === 2 ? "btn" : "btn-secondary"} px-4`} onClick={setLong}>Long</button>
              </div>
            </div>
          </motion.div>
        }
      </AnimatePresence>
    </div>
  )
}
export default SummaryLevelSelector;