import { BsTwitterX, BsGithub } from "react-icons/bs";
import { MdOutlineEmail } from "react-icons/md";
import { FaRegCopyright } from "react-icons/fa";
import darkLogo from "../../assets/DarkLogo.png";
import lightLogo from "../../assets/LightLogo.png";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../components/context/GlobalContext.jsx";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

const Footer = () => {
  const { state } = useContext(GlobalContext);
  const { pathname } = useLocation();
  const [hidden, setHidden] = useState(false)

  const onEmailClick = () => window.open("mailto:summarilysite@gmail.com")

  const DISABLED_FOOTER_ROUTES = ["/landing", "/auth", "/get-started"]
  useEffect(() => {
    setHidden(DISABLED_FOOTER_ROUTES.find(path => path === pathname))
  }, [pathname])

  return (
    <>
    {!hidden &&
      <div className="bg-inherit text-inherit bottom-0 w-screen h-28 flex phablet-max:flex-col justify-between items-center border-col border-t-2 z-50">
        <div className="p-6 flex h-full w-fit items-center justify-between">
          <img className="h-full mr-2" src={ (state.darkMode && lightLogo) || darkLogo } alt="Logo" />
        </div>
        <div className="flex items-center subtitle h-full mb-5">
          <FaRegCopyright /> Summarily
        </div>
        <div className="h-full items-center text-[3.5rem] space-x-5 flex mr-6">
          <motion.button whileTap={{ scale: 0.9 }} whileHover={{ scale: 1.1 }} onClick={onEmailClick}>
            <MdOutlineEmail />
          </motion.button>
          <motion.button whileTap={{ scale: 0.9 }} whileHover={{ scale: 1.1 }}>
            <Link to="https://twitter.com/SummarilyAI">
              <BsTwitterX />
            </Link>
          </motion.button>
          <motion.div whileTap={{ scale: 0.9 }} whileHover={{ scale: 1.1 }}>
            <Link to="https://github.com/4p02/project">
              <BsGithub />
            </Link>
          </motion.div>
        </div>
      </div>
    }
    </>
  )
}
export default Footer;