import { BACKEND_API_URL } from "../lib/Constants";

/*
    Redirects a user to login with google
    @return redirect
*/
export async function RegisterUserGoogle() {
    // TODO theres a way to get the code from the url and send it to the backend to get the token
    window.location.href = `${BACKEND_API_URL}/auth/google`;
}


/*
    Register a new user
    @param email - the email string
    @param password - the password string
    @param fullName - the full name string
    @return response - the response from the server
*/
export async function RegisterUser(email, password, fullName) {
    const response = await fetch(`${BACKEND_API_URL}/auth/register`, {
        method: 'POST',
        headers: {  
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            email: email,
            password: password,
            fullname: fullName
        })
    }).then(response => response.json());
    // maybe check for response.ok here
    if (response.detail) {
        return Promise.reject("Error");
    }
    return response;
}

/*
    Login a user
    @param email - the email string
    @param password - the password string
    @return response - the response from the server
*/
export async function LoginUser(email, password) {
    // check if token exists here maybe?

    const response = await fetch(`${BACKEND_API_URL}/auth/login`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            email: email,
            password: password
        })
    })
    .then(response => response.json());
    if (!response.token) {
        // console.debug("Login user promise rejected email and password is ", email, password, " Response detail", response.detail)
      return Promise.reject(response.detail);
    }
    // maybe check for response.ok here
    return response;
}


