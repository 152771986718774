import AccountSection from "./AccountSection"
import FormButton from "../auth/FormButton"
import toast from "react-hot-toast"
import { useState, useContext, useEffect } from "react"
import { GlobalContext } from "../context/GlobalContext.jsx";
import { dateFormatOptions } from "../../lib/Constants.js";


const DetailsSection = () => {
  const [dateJoined, setDateJoined] = useState("")
  const [email, setEmail] = useState("")
  const [status, setStatus] = useState("")
  const {state} = useContext(GlobalContext);
  const [fullName, setFullName] = useState("")
  

  useEffect(() => {
    const user = state.user;
    if (!user) {
      // Account button acts as a register/login button, so no need to pester with error message
      // toast.error("You must be logged in to see your details!");
      return;
    }
    (async () => {
      const { theName, theEmail, createdAt, theStatus } = await user.getDetails();
      if (!theName || !theEmail || !createdAt || !theStatus) {
        toast.error("Error getting user details");
        return;
      }
      setDateJoined(createdAt);
      setEmail(theEmail);
      setStatus(theStatus);
      setFullName(theName);
    })();

    
  }, [state.user])


  const onGoPro = async () => {
    const user = state.user;
    if (!user) {
      toast.error("You must be logged in to go pro!");
      return;
    }
    const didGoPro = await toast.promise(user.goPro(), {
      loading: "Going pro...",
      success: "Go pro successful!",
      error: "Error going pro"
    }).catch(err => {return null});
    if (didGoPro) {
      setStatus("Status/Pro");
    }

  }

  return (
    <AccountSection title="Account Details">
      <div className="w-full flex items-center text-left px-12">
        <div className="w-full flex flex-col mb-2">
          <p className="text-[1.25rem]"><b>Name:</b> {fullName} </p>
          <p className="text-[1.25rem]"><b>Email:</b> {email}</p>
          <p className="text-[1.25rem]"><b>Date joined:</b> {new Date(dateJoined).toLocaleString("en-us", dateFormatOptions)} </p>
          <p className="text-[1.25rem]"><b>Status:</b> {status}</p>
        </div>
        <FormButton
          onClick={onGoPro}
          width="w-1/2"
          extraClassName="!bg-[#FFD250] !text-dark"
          isSecondary
        >
          Go Pro
        </FormButton>
      </div>
    </AccountSection>
  )
}
export default DetailsSection