import { useState } from "react";
import Input from "./Input.js";

export default function PasswordInput({value, setGlobalError, setValue, label, placeholder}) {
    const [error, setError] = useState("");
    const onSetPassword = (event) => {
      setValue(event.target.value);
    }

    const onBlur = () => {
      // Break up conditions to have better error messages
      const messages = [];
      const hasUppercase = /[A-Z]/.test(value);
      const hasLowercase = /[a-z]/.test(value);
      const hasNumber = /[0-9]/.test(value);
      const hasSymbol = /[!@#$%^&*()_+=-]/.test(value);
      const validLength = value.length >= 8;
      const hasError = !(hasUppercase && hasLowercase && hasNumber && hasSymbol && validLength);

      // Collect messages
      if (!hasUppercase) messages.push("an uppercase")
      if (!hasLowercase) messages.push("a lowercase")
      if (!hasNumber) messages.push("a number")
      if (!hasSymbol) messages.push("a symbol: !@#$%^&*()_+=-")
      if (!validLength) messages.push((messages.length > 0 ? "and a " : "a ") + "length of 8 characters")

      // Display missing requirements
      setError(hasError ? ("Missing " + messages.join(", ")) : "");
      setGlobalError(hasError);

    }

    const onFocus = () => {
      setError("");
      setGlobalError(false);
    }

    return (
      <>
        <Input 
            onChange={onSetPassword}
            value={value}
            width="w-full"
            type="password"
            placeholder={placeholder || "Enter your password"}
            label={label || "Password"}
            errorMsg="Invalid password"
            error={error !== ""}
            onBlur={onBlur}
            required
            onFocus={onFocus}
        />
        {error !== "" &&
          <p className="text-red-500 font-[600] text-sm">{error}</p>
        }
      </>
    )
}