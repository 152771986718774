import { POSTGREST_API_URL, BACKEND_API_URL, FRONTEND_API_URL } from '../lib/Constants.js';
import { PostgrestClient} from "@supabase/postgrest-js";
import { GET_USER_HISTORY_QUERY } from '../lib/Constants.js';

export default class User {
    email;
    fullName;
    postgrestClient;
    constructor(token) {
        this.token = token;

        this.postgrestClient = new PostgrestClient(
          POSTGREST_API_URL,
          {
            headers: this.getHeaders({
              "Origin": `${FRONTEND_API_URL}`,
              "Access-Control-Request-Method": "POST",
              "Access-Control-Request-Headers": "Content-Type"
            })
          }
        );
    }

    getHeaders(base_headers = {}) {
        let headers = {
          "Access-Control-Allow-Origin": "*",
        };

        if (this.token != null) {
          headers["Authorization"] = `Bearer ${this.token}`;
        }

        return Object.assign(headers, base_headers);
    }

    getEmail() {
        return this.email;
    }

    getFullName() {
        return this.fullName;
    }

    getToken() {
        return this.token ?? null;
    }

    getPostGrestClient() {
        return this.postgrestClient;
    }

    /*
        Get the number of history items a user has
        @return {int} - the number of history items a user has
    */
    async getHistoryLength() {
        const response = await this.postgrestClient.from('history').select('id', {count: 'exact'})
        if (response.error) {
            // do something
            return 0;
        }
        // console.debug(response, "response from server in getHistoryLength()")
        return response.count;
    }

    /*
        Get more links from the user
        @param {int} limit - the number of links to get default 10
        @param {int} offset - the number of links to skip
        @return {Array} - an array of links
    */
    async getLinks(limit, offset) {
        const response = await this.postgrestClient.from('history').select(GET_USER_HISTORY_QUERY).range(offset, limit);
        if (response.error) {
            // do something
            return [];
        }
        // console.debug(response, "response from the server in getLinks")
        return this.formatLinks(response.data);

        // return await this.getDocumentsInParallel(raw_links)
    }

    /*
        Format the links from the user so it can be used in history
        @param {Array} raw_links - the raw links from the user
        @return {Array} - the formatted history
    */
    async formatLinks(raw_links) {
        if (!raw_links) {
            return [];
        }

        return raw_links.map(link => {
            return {
                id: link.document_id,
                title: link.documents.title,
                image: link.documents.image === null ? null : this.bytesToString(link.documents.image),
                link: link.documents.source_url,
                date: link.documents.created_at,
                summary: this.bytesToString(link.documents.summary),

            }
        })
    }

    /*
        Parse the image in hex to base64 image
        @param {Array} data - the raw image from the user bytea
        @return {string} - the formatted image
    */
    parseImage(data) {
        const hexArray = new Uint8Array(data.match(/.{1,3}/g)).map(byte => parseInt(byte, 16));
        const base64 = window.btoa(String.fromCharCode.apply(null, hexArray));
        return `data:image/png;base64,${base64}`;
    }

    /*
        Parse array of bytea in utf8 to String
        @param {Array} data - the raw image from the user bytea
        @return {string} - the formatted image
    */
    bytesToString(data) {
        const hexArray = new Uint8Array(data.match(/.{1,2}/g).map(byte => parseInt(byte, 16)));
        const textDecoder = new TextDecoder();
        return textDecoder.decode(hexArray);
    }


    /*
        Delete the user account
        @param {string} password - the password of the user
        @return {boolean} - true if the account was delete false otherwise
    */
    async deleteAccount(password) {
        const response = await fetch(`${BACKEND_API_URL}/delete/user`, {
            method: "POST",
            headers: this.getHeaders({'Content-Type': 'application/json'}),
            body: JSON.stringify({
                password: password
            })
        })
        if (response.status === 200) {
            return Promise.resolve(true);
        }
        return Promise.reject(false);
    }


    /*
        Change the user's email
        @param {string} newEmail - the new email
        @return {Promise resolved} - if the email was changed true
    */
    async changeEmail(newEmail) {
        const response = await this.postgrestClient.from('users').update({
            email: newEmail
        })
        if (response.error) {
            // do something
            return Promise.reject(response.error);
        }
        return Promise.resolve(true);
    }
    /*
        Send a pro payment to the server
        @return {Promise<boolean>} - True if the payment was successful

    */
    async goPro() {
        const response = await fetch(`${BACKEND_API_URL}/pro/payment`, {
            method: "POST",
            headers: this.getHeaders({'Content-Type': 'application/json'}),
        });
        if (response.status === 200) {
            return Promise.resolve(true);
        }
        return Promise.reject(false);
    }

    async getDetails() {
        const details = await this.postgrestClient.from('users').select('email, fullname, created_at').single().limit(1);

        // console.log(details, "details from server in getDetails()", this.token)
        if (details.error) {
            // do something
            return {};
        }
        return {
            theName: details.data.fullname,
            theEmail: details.data.email,
            createdAt: details.data.created_at,
            theStatus: await this.getStatus()
        }
    }

    async getStatus() {
        const response = await fetch(`${BACKEND_API_URL}/get/status`, {
            method: "GET",
            headers: this.getHeaders(),
        }).then(response => response.json())
        return response.status;
    }


    async updatePassword(newPassword, password) {
        try {
            const response = await fetch(`${BACKEND_API_URL}/change/password`, {
                method: 'POST',
                headers: this.getHeaders({'Content-Type': 'application/json'}),
                body: JSON.stringify({
                    password: password,
                    newpassword: newPassword
                })
            });
            return response;
        } catch(error) {
            return Promise.reject(new Error(error));
        }
    }

}
