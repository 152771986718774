import { useContext, useEffect, useState } from "react";
import RegisterView from "../components/auth/RegisterView.js";
import LoginView from "../components/auth/LoginView.js";
import { useSearchParams } from "react-router-dom";
import { GlobalContext } from "../components/context/GlobalContext.jsx";
import { CheckTokenAndState } from "../lib/Constants.js";
import { useLocation } from "react-router-dom";

const Auth = () => {
  const [loginPageOrRegister, setLoginPageOrRegister] = useState(true);
  const { state, dispatch } = useContext(GlobalContext);
  const [searchParams] = useSearchParams();
  const { state: queryState } = useLocation();
  const toggleView = () => setLoginPageOrRegister(prev => !prev)
  
  useEffect(() => {
    const cookieToken = localStorage.getItem("token");
    if (CheckTokenAndState(cookieToken, state, dispatch)) {
      window.location.href = "/"      
    }
    const token = searchParams.get('token');

    if (CheckTokenAndState(token, state, dispatch)) {
      localStorage.setItem("token", token)
      window.location.href = "/"
    }
    if (queryState?.isSignIn) setLoginPageOrRegister(false);

  }, [])
  return (
    <div className="page !pt-0">
      <h1 className="title text-center">
        {loginPageOrRegister ?
          "Log Into Your Account"
        :
          "Create an Account"
        }
      </h1>
      
      <div className="w-full sm:w-11/12 md:w-3/4 lg:w-3/5">
        {loginPageOrRegister ?
          <LoginView viewToggle={toggleView} />
        :
          <RegisterView viewToggle={toggleView} />
        }
      </div>
    </div>
  )
}
export default Auth;