import { useContext, useState } from "react"
import FormButton from "../auth/FormButton"
import PasswordInput from "../inputs/PasswordValidInput"
import AccountSection from "./AccountSection"
import { GlobalContext } from "../context/GlobalContext"
import toast from "react-hot-toast";

const DeleteAccountSection = () => {
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const {state, dispatch} = useContext(GlobalContext)
  const onDeleteAccount = async () => {
      if (!state || !state.user) {
        toast.error("Error deleting account");
        return;
      }
      const user = state.user;
      const response = await toast.promise(user.deleteAccount(password), {
        loading: "Deleting account...",
        success: "Account deleted",
        error: "Error deleting account"
      }).catch(err => {return false});
      if (response) {
        dispatch({type: "LOGOUT"})
        window.location.href = "/";
      }
  }

  return (
    <AccountSection title="Delete Account">
      <div className="w-full flex flex-col items-center text-left">
        <div className="w-2/3 space-x-4 flex mb-2">
          <PasswordInput
            value={password}
            setGlobalError={setError}
            setValue={setPassword}
          />

        </div>
        <FormButton
          onClick={onDeleteAccount}
          width="w-1/2"
          disabled={error}
        >
          Delete
        </FormButton>
      </div>
    </AccountSection>
  )
}
export default DeleteAccountSection