
import { useEffect, useContext, useState } from "react";
import { GlobalContext } from "../components/context/GlobalContext";
import HistoryCard from "../components/HistoryCard";
import { PAGE_SIZE } from "../lib/Constants.js";
import FlatList from "flatlist-react";

const History = () => {
  const { state } = useContext(GlobalContext);
  const [history, setHistory] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [page, setPage] = useState(0);

  useEffect(() => {
    // userContext.user 
    // console.debug("State of user is ", state)
    if (!state || !state.user || state.user === undefined) {
      console.error("User not logged in! (something went wrong with context)")
      // Prob add a redirect
    } else {

      (async () => {
        const totalHistory = await state.user.getHistoryLength();
        // console.debug("history response"+totalHistory);
        setTotalItems(totalHistory);
        await loadMore()
      })();
    }
  }, [state]);

  const loadMore = async () => {
    setPage(page + 1);
    if (!state.user) {
      console.error("load more: User not logged in! (something went wrong with context)")
      return;
    }
    const historyItems = await state.user.getLinks(PAGE_SIZE, page * PAGE_SIZE);
    setHistory(historyItems)

  }



  const renderItem = (item) => {
    if (!item || !item.title) return;
    return <HistoryCard key={item.id}  title={item.title} desc={item.summary} link={item.link} image={item.image} date={item.date} />
  }

  const whenEmpty = () => {
    return (
      <h1>Summaries will appear here</h1>
    )
  }

  const whenLoading = () => {
    return (
      <h1>Loading...</h1>
    )
  }


  return (
    <div className="page lg-max:p-8 lg:p-20 xl:p-28">
      <div className="flex flex-col w-full">
        <h1 className="title lg-max:text-center">Recently Searched Links</h1>
        <p className="subtitle mb-12 lg-max:text-[1.25rem] lg-max:text-center">Discover the shortened versions of recently searched links for easy sharing and reference.</p>
        <div className="flex justify-center w-full h-fit flex-wrap xl:flex-nowrap phablet-max:space-y-10">
          <FlatList 
            paginationLoadingIndicator={whenLoading}
            pagination={{
              fetchMore: loadMore,
            }}
            renderWhenEmpty={whenEmpty} 
            list={history}
            renderItem={renderItem}
            hasMoreItems={history ? history.length < totalItems : false}

            key={"flatlist-key"}

          />
        </div>
      </div>
    </div>
  )
}
export default History;